import Head from "next/head";
import Script from "next/script";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Button, Typography } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { createEmotionCache } from "../utils/create-emotion-cache";
import {
  registerRequestIntercept,
  registerResponseIntercept,
} from "../apis/axios";
import { DashboardLayout } from "../components/dashboard-layout";

import { Toastr } from "../components/toastr";
const clientSideEmotionCache = createEmotionCache();
import "../styles/globals.css";
import { useState, useEffect } from "react";
import * as gtag from "../lib/gtag";
import { useRouter } from "next/router";
import axios from "axios";
import verifyUser from "../helper/verifyUser";
import { ColorModeProvider } from "../contexts/ColorModeContext";
import { AuthProvider } from "../contexts/AuthContext";
import { SaleChannelProvider } from "../contexts/SalesChannelContext";
import { LicenseInfo } from "@mui/x-license-pro";
import { LoaderProvider } from "../contexts/LoaderContext";
import { ExtensionProvider } from "../contexts/ExtensionContext";
import Loader from "../components/Loader";
import { TourProvider } from "@reactour/tour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { DashboardProvider } from "../contexts/DashboardContext";
import amplitude from "amplitude-js";
import { getUserProperties } from "../components/utils/userProperties";
import { TradeMarkMonitorProvider } from "../contexts/TradeMarkMonitorContext";
import MaintainanceModal from "../components/dashboardComponents/MaintainanceModal";
import { getCookie, setCookie } from "../helper/cookies";
import PricingModal from "../components/pricing/PricingModal";
import SystemDownModal from "../components/dashboardComponents/SystemDownModal";
import { getNewToken, googleAnalyticsUsers } from "../apis/account";
import * as Sentry from "@sentry/nextjs";
import crypto from "crypto";
import { clearGistCookies, gistIdentify } from "../helper/gistIdentify";
import CardFailModal from "../components/CardFailModal/CardFailModal";
import MaintenancePage from "./maintainance";

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_LICENSE_KEY);
const App = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const [openMaintenanceModal, setOpenMaintenanceModal] = useState(false);
  const [openPricingModal, setOpenPricingModal] = useState(false);
  const [openSystemDownModal, setOpenSystemDownModal] = useState(false);
  const [openCardFailModal, setOpenCardFailModal] = useState([]);

  useEffect(() => {
    registerRequestIntercept();
    registerResponseIntercept(
      setOpenPricingModal,
      setOpenMaintenanceModal,
      setOpenSystemDownModal
    );
  }, []);
  const SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY;
  const getLayout = Component.getLayout ?? ((page) => page);
  const everbeeToken = getCookie(`${process.env.NEXT_PUBLIC_AUTH_COOKIE_NAME}`);
  let loggedInUserEmail;
  const router = useRouter();
  const { redirectToPricing } = router.query;

  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    if (router.isReady) {
      if (everbeeToken) {
        getCurrentUser();
      } else if (localStorage.getItem("everbeeEmail")) {
        fetchNewTokenAndStore();
      } else {
        console.log("logout 1");
        window.location.href = `${process.env.NEXT_PUBLIC_AUTH_FRONTEND_APP}/login?redirect_to=${window.location.href}`;
      }
    }
  }, [everbeeToken, router.isReady]);

  /** webflow */
  useEffect(() => {
    if (router.isReady) {
      // everbeeToken && setCookie("everbeeId", everbeeToken || "", 180);
      if (router.pathname == "/redirectingLoader") {
        if (everbeeToken) {
          router.push("/getting-started");
        } else {
          // window.location.href = "https://auth.everbee.io/login";
          console.log("logout 2");
        }
      }
    }
  }, [router.isReady]);

  useEffect(() => {
    const checkCookie = () => {
      var everbeeCookie = getCookie(
        `${process.env.NEXT_PUBLIC_AUTH_COOKIE_NAME}`
      );

      if (!everbeeCookie) {
        // Token not found, redirect to login
        clearGistCookies();
        window.location.href = `${process.env.NEXT_PUBLIC_AUTH_FRONTEND_APP}/logout?redirect_to=${window.location.href}`;
      }
    };

    // Set up polling to check the cookie every 5 seconds
    const intervalId = setInterval(checkCookie, 5000);

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, []);

  //RevGems page view
  useEffect(() => {
    let originUrl;
    // Keep track of where we're navigating from (for the referrer)
    const handleRouteChangeStart = (url) => {
      originUrl = document.location.href;
    };

    const handleRouteChangeComplete = (url) => {
      window.revgems("pageView", { referrer: originUrl }); // 👈 TRACK PAGE VIEW IN REVGEMS
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    // If the component is unmounted, unsubscribe from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [router.events]);
  //
  // Should be removed after 2 sprints expected sprint 40 SSO
  async function fetchNewTokenAndStore() {
    try {
      const response = await getNewToken();
      if (response?.data?.new_access_token) {
        setCookie(
          `${process.env.NEXT_PUBLIC_AUTH_COOKIE_NAME}`,
          response?.data?.new_access_token,
          {
            expires: 7,
            domain: ".everbee.io",
          }
        );
        localStorage.removeItem("userData");
        localStorage.removeItem("everbeeToken");
        localStorage.removeItem("everbeeEmail");
        localStorage.removeItem("everbeeUserId");
        localStorage.removeItem("everbeeName");
        localStorage.removeItem("everbeeForgotEmail");
        localStorage.removeItem("everbeePrevURL");
        localStorage.removeItem("everbeeFavCategory");
        router.reload();
      }
    } catch (err) {
      console.log(err, "error 1");
    }
  }
  //
  let loggedInUserId;
  async function getCurrentUser() {
    try {
      const res = await axios.get(
        `${process.env.NEXT_PUBLIC_SSO_APP}/users/show`
      );
      const userData = res?.data;
      localStorage.setItem("userData", JSON.stringify(userData));
      loggedInUserEmail = userData?.email;
      loggedInUserId = userData?.id;
      Sentry.setUser({ email: userData?.email });
      hj("identify", userData?.id, {
        email: userData?.email,
      });
      if (userData?.new_sso_auth_token) {
        console.log("182");
        setCookie(
          `${process.env.NEXT_PUBLIC_AUTH_COOKIE_NAME}`,
          userData?.new_sso_auth_token,
          {
            expires: 7,
            domain: ".everbee.io",
          }
        );
      }
      var userProperties = getUserProperties(userData);
      amplitude
        .getInstance()
        .init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY, userData?.id);
      googleAnalyticsUsers({
        user_name: { value: userData?.name },
        user_email: { value: userData?.email },
      });
      amplitude.getInstance().setUserProperties(userProperties);
      if (res.data.affiliate_cookie) {
        setCookie("rewardful.referral", res.data.affiliate_cookie, 180);
      }
      if (
        !localStorage.getItem("firstVisit") &&
        userData?.onboarding_flow === false
      ) {
        localStorage.setItem("firstVisit", true);
        amplitude.getInstance().logEvent("First Visit");
      }
      gistIdentify(userData?.email);

      _cio.identify({
        // Required attributes
        email: userData?.email,
        // new_event: "true",
      });

      //churnkey failed payment wall

      if (
        userData.stripe_customer_id &&
        window.churnkey &&
        typeof window.churnkey.check === "function"
      ) {
        const user_hash = crypto
          .createHmac("sha256", process.env.NEXT_PUBLIC_CHURNKEY_API_KEY)
          .update(userData.stripe_customer_id)
          .digest("hex"); // Send to front-end

        window.churnkey.check("failed-payment", {
          customerId: userData.stripe_customer_id,
          authHash: user_hash,
          appId: "npw2ljajg",
          mode:
            process.env.NEXT_PUBLIC_NODE_ENV === "production" ? "live" : "test",
          provider: "stripe",
          softWall: false, // set to true to allow exiting wall
          forceCheck: false, // recommended to leave this to false to avoid redundant checks
          gracePeriodDays: 0, // allow a grace period in which failed payment wall is shown but can be exited (soft wall)
          ignoreInvoicesWithoutAttempt: false, // set to true to ignore invoices without a failed charge

          handleSupportRequest() {
            window.location.href = "https://help.everbee.io";
            // optional, if defined, a "Contact Support" button will show
          },
          async handleCancel(customer) {
            const res = await axios.get(
              `${process.env.NEXT_PUBLIC_SSO_APP}/current_plan/manage`
            );
            window.location.href = res.data.redirect_url;
            // optional, if defined, a "Cancel Subscription" button will show
          },
          onUpdatePaymentInformation(customer) {
            // optional, called after customer updates payment information and overdue invoice is charged
          },
          onFailedPaymentWallActivated() {
            // optional, called when wall is activated
          },
          onFailedPaymentWallClose() {}, // optional
          onCancel(customer) {}, // optional
          onError(error, type) {
            // types below
          },
        });
      }
      if (redirectToPricing) {
        router.push("/pricing");
      }
    } catch (err) {
      //need to remove after SSO

      // Use safe navigation to check if the error response status is 401 (Unauthorized)
      if (err?.response?.status === 401) {
        // Need to remove after SSO
        localStorage.removeItem("userData");
        localStorage.removeItem("everbeeToken");
        localStorage.removeItem("everbeeEmail");
        localStorage.removeItem("everbeeUserId");
        localStorage.removeItem("everbeeName");
        localStorage.removeItem("everbeeForgotEmail");
        localStorage.removeItem("everbeePrevURL");
        localStorage.removeItem("everbeeFavCategory");
        clearGistCookies();

        // Redirect to the logout page with the redirect_to parameter
        window.location.href = `${process.env.NEXT_PUBLIC_AUTH_FRONTEND_APP}/logout?redirect_to=${window.location.href}`;
      } else {
        console.error("An error occurred:", err);
      }
    } finally {
      setIsAuth(true);
    }
  }

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  /* webtour */
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  const steps = [];

  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `
          var _cio = _cio || [];
      (function () {
        var a, b, c;
        a = function (f) {
          return function () {
            _cio.push([f].concat(Array.prototype.slice.call(arguments, 0)));
          };
        };
        b = ["load", "identify", "sidentify", "track", "page"];
        for (c = 0; c < b.length; c++) {
          _cio[b[c]] = a(b[c]);
        }
        var t = document.createElement("script"),
          s = document.getElementsByTagName("script")[0];
        t.async = true;
        t.id = "cio-tracker";
        t.setAttribute("data-site-id", "02c3103647e74a5bf23c");
        t.setAttribute("data-base-url", "https://customerioforms.com");
        t.setAttribute("data-use-array-params", "true");

        // To disable in-app messages, remove the two lines below
        t.setAttribute(
          "data-in-app-org-id",
          "aebb2a40-45e2-436e-86e1-8ce977dc069c"
        );
        t.setAttribute("data-use-in-app", "true");
        // If data-in-app-org-id was not filled above, go to
        // the following link to find it: https://fly.customer.io/env/last/settings/actions/in_app'
        t.src = "https://assets.customer.io/assets/track.js";

        s.parentNode.insertBefore(t, s);
      })();`,
        }}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-P4VTCMN');
          `,
        }}
      />
      <Script
        id="drip-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              var _dcq = _dcq || [];
              var _dcs = _dcs || {};
              _dcs.account = '8644055';

              (function() {
                var dc = document.createElement('script');
                dc.type = 'text/javascript'; dc.async = true;
                dc.src = '//tag.getdrip.com/8644055.js';
                var s = document.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(dc, s);
              })();
          `,
        }}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      {loggedInUserEmail && (
        <Script
          id="canny-init"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
          !function(w,d,i,s){function l(){if(!d.getElementById(i)){var f=d.getElementsByTagName(s)[0],e=d.createElement(s);e.type="text/javascript",e.async=!0,e.src="https://canny.io/sdk.js",f.parentNode.insertBefore(e,f)}}if("function"!=typeof w.Canny){var c=function(){c.q.push(arguments)};c.q=[],w.Canny=c,"complete"===d.readyState?l():w.attachEvent?w.attachEvent("onload",l):w.addEventListener("load",l,!1)}}(window,document,"canny-jssdk","script");
          Canny('identify', {
              appID: '6357008e90b4f64bebdabea6',
              user: {
                email: '${loggedInUserEmail}',
                name: '${loggedInUserEmail?.split("@")[0]}',
                id: '${loggedInUserId}',
              },
            })`,
          }}
        />
      )}
      <Script
        id="gist-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(d,h,w){var gist=w.gist=w.gist||[];gist.methods=['trackPageView','identify','track','setAppId'];gist.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);gist.push(e);return gist;}};for(var i=0;i<gist.methods.length;i++){var c=gist.methods[i];gist[c]=gist.factory(c)}s=d.createElement('script'),s.src="https://widget.getgist.com",s.async=!0,e=d.getElementsByTagName(h)[0],e.appendChild(s),s.addEventListener('load',function(e){},!1),gist.setAppId("7tn4opfe"),gist.trackPageView()})(document,'head',window); `,
        }}
      />
      {loggedInUserEmail && (
        <Script
          id="profitwell-js"
          data-pw-auth="4e0c385eb4348bada73025c5c7f3fdca"
          dangerouslySetInnerHTML={{
            __html: `
            (function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
            a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
            s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
            })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');
            profitwell('start', { 'user_email': '${loggedInUserEmail}' });`,
          }}
        />
      )}
      <Script
        id="usetiful-js"
        dangerouslySetInnerHTML={{
          __html: `
              (function (w, d, s) {
      var a = d.getElementsByTagName('head')[0];
      var r = d.createElement('script');
      r.async = 1;
      r.src = s;
      r.setAttribute('id', 'usetifulScript');
      r.dataset.token = "5cc0a1a980a08c3ddcdeb84db1ffa42c";
      a.appendChild(r);
    })(window, document, "https://www.usetiful.com/dist/usetiful.js");`,
        }}
      />
      <Script
        id="stonly-js"
        dangerouslySetInnerHTML={{
          __html: `
                !function (s, t, o, n, l, y, w, g) {
      s.StonlyWidget || ((w = s.StonlyWidget = function () {
        w._api ? w._api.apply(w, arguments) : w.queue.push(arguments)
      }).scriptPath = n, w.queue = [], (y = t.createElement(o)).async = !0,
        (g = new XMLHttpRequest).open("GET", n + "version?v=" + Date.now(), !0), g.onreadystatechange = function () {
          4 === g.readyState && (y.src = n + "stonly-widget.js?v=" + (200 === g.status ? g.responseText : Date.now()),
            (l = t.getElementsByTagName(o)[0]).parentNode.insertBefore(y, l))
        }, g.send())
    }(window, document, "script", "https://stonly.com/js/widget/v2/");`,
        }}
      />
      <Script
        id="churnkey"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          !function(){  
  if (!window.churnkey || !window.churnkey.created) {
    window.churnkey = { created: true };
    const a = document.createElement('script');
    a.src = 'https://assets.churnkey.co/js/app.js?appId=npw2ljajg';
    a.async = true;
    const b = document.getElementsByTagName('script')[0];
    b.parentNode.insertBefore(a, b);
  }
}();
          `,
        }}
      />
      <Script
        id="amplitude-marketing"
        dangerouslySetInnerHTML={{
          __html: `
          !function(){"use strict";!function(e,t){var r=e.amplitude||{_q:[],_iq:[]};if(r.invoked)e.console&&console.error&&console.error("Amplitude snippet has been loaded.");else{var n=function(e,t){e.prototype[t]=function(){return this._q.push({name:t,args:Array.prototype.slice.call(arguments,0)}),this}},s=function(e,t,r){return function(n){e._q.push({name:t,args:Array.prototype.slice.call(r,0),resolve:n})}},o=function(e,t,r){e[t]=function(){if(r)return{promise:new Promise(s(e,t,Array.prototype.slice.call(arguments)))}}},i=function(e){for(var t=0;t<m.length;t++)o(e,m[t],!1);for(var r=0;r<y.length;r++)o(e,y[r],!0)};r.invoked=!0;var a=t.createElement("script");a.type="text/javascript",a.integrity="sha384-PPfHw98myKtJkA9OdPBMQ6n8yvUaYk0EyUQccFSIQGmB05K6aAMZwvv8z50a5hT2",a.crossOrigin="anonymous",a.async=!0,a.src="https://cdn.amplitude.com/libs/marketing-analytics-browser-0.3.2-min.js.gz",a.onload=function(){e.amplitude.runQueuedFunctions||console.log("[Amplitude] Error: could not load SDK")};var c=t.getElementsByTagName("script")[0];c.parentNode.insertBefore(a,c);for(var u=function(){return this._q=[],this},p=["add","append","clearAll","prepend","set","setOnce","unset","preInsert","postInsert","remove","getUserProperties"],l=0;l<p.length;l++)n(u,p[l]);r.Identify=u;for(var d=function(){return this._q=[],this},v=["getEventProperties","setProductId","setQuantity","setPrice","setRevenue","setRevenueType","setEventProperties"],f=0;f<v.length;f++)n(d,v[f]);r.Revenue=d;var m=["getDeviceId","setDeviceId","getSessionId","setSessionId","getUserId","setUserId","setOptOut","setTransport","reset"],y=["init","add","remove","track","logEvent","identify","groupIdentify","setGroup","revenue","flush"];i(r),r.createInstance=function(){var e=r._iq.push({_q:[]})-1;return i(r._iq[e]),r._iq[e]},e.amplitude=r}}(window,document)}();

amplitude.init("4f4687ed7a181f2515c1ca7f3e8279eb");
          `,
        }}
      />
      <Script
        src={`https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`}
      />
      <Head>
        <title>EverBee Research</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link
          href="https://js.stripe.com/v3/fingerprinted/css/checkout-194a2e4c9c92b2bb8a6a5a482f56659f.css"
          rel="stylesheet"
        ></link>
        {/* <script
          async
          data-domains="everbee.io, app.everbee.io, proud-luxurious-wandflower.glitch.me"
          src="https://r.wdfl.co/rw.js"
          data-rewardful="605017"
        ></scrip> */}
      </Head>
      {isAuth ? (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ColorModeProvider>
            <LoaderProvider>
              <Toastr />
              <ExtensionProvider>
                <GoogleOAuthProvider
                  clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
                >
                  <AuthProvider>
                    <SaleChannelProvider>
                      <DashboardProvider>
                        <TradeMarkMonitorProvider>
                          <TourProvider
                            steps={steps}
                            afterOpen={disableBody}
                            beforeClose={enableBody}
                            // defaultOpen
                            scrollSmooth={true}
                            inViewThreshold={100}
                            showDots={false}
                            badgeContent={({ currentStep, totalSteps }) => (
                              <Typography
                                component="span"
                                fontSize="12px"
                                padding="3px"
                                color="fontColor.fontColorPrimary"
                              >
                                {`${currentStep + 1}/${totalSteps}`}
                              </Typography>
                            )}
                            prevButton={({
                              currentStep,
                              setCurrentStep,
                              steps,
                            }) => {
                              const first = currentStep === 0;
                              return (
                                <Button
                                  sx={{
                                    visibility: first ? "hidden" : "visible",
                                    textTransform: "capitalize",
                                  }}
                                  onClick={() => {
                                    setCurrentStep((s) => s - 1);
                                  }}
                                >
                                  Back
                                </Button>
                              );
                            }}
                            nextButton={({
                              currentStep,
                              stepsLength,
                              setIsOpen,
                              setCurrentStep,
                              steps,
                            }) => {
                              const last = currentStep === stepsLength - 1;
                              return (
                                <Button
                                  sx={{ textTransform: "capitalize" }}
                                  onClick={() => {
                                    if (last) {
                                      setIsOpen(false);
                                      setCurrentStep(0);
                                    } else {
                                      setCurrentStep((s) =>
                                        s === steps?.length - 1 ? 0 : s + 1
                                      );
                                    }
                                  }}
                                >
                                  {last ? "Completed" : "Next"}
                                </Button>
                              );
                            }}
                            styles={{
                              popover: (base) => ({
                                ...base,
                                // "--reactour-accent": "#ef5a3d",
                                borderRadius: 10,
                                paddingBottom: "13px",
                              }),
                              badge: (base) => ({
                                ...base,
                                left: "auto",
                                right: "-0.8125em",
                                height: "20px",
                                width: "35px",
                                display: "inline-flex",
                                justifyContent: "center",
                              }),
                              controls: (base) => ({
                                ...base,
                                marginTop: 20,
                              }),
                              close: (base) => ({
                                ...base,
                                right: "auto",
                                left: 8,
                                top: 8,
                              }),
                            }}
                          >
                            {router.pathname === "/checkExtension" ? (
                              <Component {...pageProps} />
                            ) : (
                              <DashboardLayout getCurrentUser={getCurrentUser}>
                                <Component {...pageProps} />
                              </DashboardLayout>
                            )}
                            {/* //  {getLayout(<Component {...pageProps} />)} */}
                            <MaintainanceModal
                              openModal={openMaintenanceModal}
                              handleClose={() => setOpenMaintenanceModal(false)}
                            />
                            <PricingModal
                              openModal={openPricingModal}
                              setOpenPricingModal={setOpenPricingModal}
                              setOpenCardFailModal={setOpenCardFailModal}
                            />
                            <SystemDownModal
                              open={openSystemDownModal}
                              setOpenSystemDownModal={setOpenSystemDownModal}
                            />
                            {openCardFailModal.length > 0 && (
                              <CardFailModal
                                handleClose={() => setOpenCardFailModal([])}
                                openCardFailModal={openCardFailModal}
                              />
                            )}
                            {/* //  </> <NpsSurvey steps={stepsConfig} /> */}
                          </TourProvider>
                        </TradeMarkMonitorProvider>
                      </DashboardProvider>
                    </SaleChannelProvider>
                  </AuthProvider>
                </GoogleOAuthProvider>
              </ExtensionProvider>
            </LoaderProvider>
          </ColorModeProvider>
        </LocalizationProvider>
      ) : (
        <Loader isLoading={true} />
      )}
    </>
  );
};

export default App;
